import moment from 'moment'
import 'moment-timezone'

export const secondsDiff = (a, b) => {
  const now = moment(a)
  const end = moment(b)
  return now.diff(end, 'seconds');
}

export const formatTime = (time, tz, timeFormat) => {
  return moment.utc(time).tz(tz).format(timeFormat)
}

export const formatTimeFromNow = (time, tz) => {
  return moment.utc(time).tz(tz).fromNow()
}

export const formatTimeFromNowNumeric = (time, tz) => {
  const now = moment().tz(tz)
  const target = moment(time).tz(tz)

  const diffInSeconds = Math.abs(now.diff(target, 'seconds'))

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`
  } else {
    const minutes = Math.floor(diffInSeconds / 60)
    return `${minutes}m`
  }
}
