<script setup lang="ts">
import { computed, h, nextTick, ref, render, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { type FlattenedItem, TreeItem } from 'radix-vue'
import { unrefElement } from '@vueuse/core'
import bbox from '@turf/bbox'

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import { draggable, dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { type Instruction, type ItemMode, attachInstruction, extractInstruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item'

import {Eye, EyeClosed, Settings, ChevronRight, ScanEye} from 'lucide-vue-next'
import { Slider } from '@/components/ui/slider'
import { Button } from '@/components/ui/button'
import { MapLayer, MapSource } from '@/components/map'
import { LayerPlanetVars, LayerPlanetscope, LayerMosaic } from '@/components/layers'

const emit = defineEmits(['mounted'])
const props = defineProps<{
  item: FlattenedItem<any>,
  prevItem: FlattenedItem<any>,
}>()

const layerId = `layer-${props.item.value.uuid}`
const beforeId = props.prevItem ? `layer-${props.prevItem.value.uuid}` : 'default-layer'

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])

const elRef = ref()
const isVisible = ref(props.item.value.properties?.visible ?? true)
const isSettings = ref(false)
const isDragging = ref(false)
const isDraggedOver = ref(false)
const isInitialExpanded = ref(false)
const instruction = ref<Extract<Instruction, { type: 'reorder-above' | 'reorder-below' | 'make-child' }> | null>(null)

const toggleVisibility = () => {
  isVisible.value = !isVisible.value

  const layers = map.value.getStyle().layers.filter((layer: any) =>
    layer.metadata?.groupId === layerId
  )

  layers.forEach((layer: any) => {
    map.value.setLayoutProperty(
      layer.id,
      'visibility',
      isVisible.value ? 'visible' : 'none'
    )
  })

  const layer = props.item.value
  layer.properties.visible = isVisible.value
  store.commit('layers/UPDATE_LAYER', layer)
}

const toggleSettings = () => {
  isSettings.value = !isSettings.value
}

const focusOnGeometry = () => {
  const geometry = props.item.value?.geometry

  if (geometry) {
    const bounds = bbox(geometry)
    map.value.fitBounds(bounds, { padding: 300 })
  }
}

const mode = computed(() => {
  if (props.item.hasChildren)
    return 'expanded'
  if (props.item.index + 1 === props.item.parentItem?.children?.length)
    return 'last-in-group'
  return 'standard'
})

const handleMounted = () => {
  emit('mounted')
}

const opacity = ref(100)

const onOpacityChange = (value: any) => {
  opacity.value = value[0]

  const layers = map.value.getStyle().layers.filter((layer: any) =>
    layer.metadata?.groupId === layerId
  )

  layers.forEach((layer: any) => {
    // change layer opacity based on type
    if (layer.type === 'fill') {
      map.value.setPaintProperty(
        layer.id,
        'fill-opacity',
        opacity.value / 100
      )
    } else if (layer.type === 'line') {
      map.value.setPaintProperty(
        layer.id,
        'line-opacity',
        opacity.value / 100
      )
    } else if (layer.type === 'circle') {
      map.value.setPaintProperty(
        layer.id,
        'circle-opacity',
        opacity.value / 100
      )
    } else if (layer.type === 'symbol') {
      map.value.setPaintProperty(
        layer.id,
        'text-opacity',
        opacity.value / 100
      )
    } else if (layer.type === 'raster') {
      map.value.setPaintProperty(
        layer.id,
        'raster-opacity',
        opacity.value / 100
      )
    }
  })
}

watchEffect((onCleanup) => {
  const currentElement = unrefElement(elRef)

  if (!currentElement)
    return

  const item = { ...props.item.value, level: props.item.level, id: props.item._id }

  const expandItem = () => {
    if (!elRef.value?.isExpanded) {
      elRef.value?.handleToggle()
    }
  }

  const closeItem = () => {
    if (elRef.value?.isExpanded) {
      elRef.value?.handleToggle()
    }
  }

  const dndFunction = combine(
    draggable({
      element: currentElement,
      getInitialData: () => item,
      onDragStart: () => {
        isDragging.value = true
        isInitialExpanded.value = elRef.value?.isExpanded
        closeItem()
      },
      onDrop: () => {
        isDragging.value = false
        if (isInitialExpanded.value)
          expandItem()
      },
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
          render: ({ container }) => {
            return render(h(
              'div',
              { class: 'bg-white text-blackA11 rounded-md text-sm font-medium px-3 py-1.5' },
              item.name,
            ), container)
          },
          nativeSetDragImage,
        })
      },
    }),

    dropTargetForElements({
      element: currentElement,
      getData: ({ input, element }) => {
        const data = { id: item.id }

        return attachInstruction(data, {
          input,
          element,
          indentPerLevel: 16,
          currentLevel: props.item.level,
          mode: mode.value,
          block: [],
        })
      },
      canDrop: ({ source }) => {
        return source.data.id !== item.id
      },
      onDrag: ({ self }) => {
        instruction.value = extractInstruction(self.data) as typeof instruction.value
      },
      onDragEnter: ({ source }) => {
        if (source.data.id !== item.id) {
          isDraggedOver.value = true
          expandItem()
        }
      },
      onDragLeave: () => {
        isDraggedOver.value = false
        instruction.value = null
      },
      onDrop: ({ location }) => {
        isDraggedOver.value = false
        instruction.value = null
        if (location.current.dropTargets[0].data.id === item.id) {
          nextTick(() => {
            expandItem()
          })
        }
      },
      getIsSticky: () => true,
    }),

    monitorForElements({
      canMonitor: ({ source }) => {
        return source.data.id !== item.id
      },
    }),
  )

  onCleanup(() => dndFunction())
})
</script>

<template>
  <TreeItem
    ref="elRef"
    v-slot="{ isExpanded }"
    :value="item.value"
    :level="item.level"
    class="relative w-full border-b border-gray-200 rounded-none"
    :class="{ 'opacity-50': isDragging }"
  >
    <div class="flex justify-between items-center w-full">
      <div class="flex items-center">
        <!-- <Button
          variant="ghostDark"
          class="w-7 h-7 mr-0.5 p-0 text-gray-400 rounded-full hover:text-black"
          :class="{'rotate-90 text-black': isExpanded}"
        >
          <ChevronRight class="w-7 h-5" />
        </Button> -->
        <div class="ml-2">
          <h3>{{item.value.properties.name}}</h3>
        </div>
      </div>
      <div>
        <Button size="sm" variant="ghost" class="w-7 h-7 p-0 rounded-full" @click="focusOnGeometry">
          <ScanEye class="text-gray-500 w-4 h-4" />
        </Button>
        <Button size="sm" variant="ghost" class="w-7 h-7 p-0 rounded-full" @click="toggleVisibility">
          <EyeClosed class="text-gray-500 w-4 h-4" v-if="!isVisible" />
          <Eye class="text-primary w-4 h-4" v-if="isVisible" />
        </Button>
        <Button size="sm" variant="ghost" class="w-7 h-7 p-0 rounded-full" @click="toggleSettings">
          <Settings class="w-4 h-4" :class="{'text-primary': isSettings, 'text-gray-500': !isSettings}" />
        </Button>
        <!-- <DialogConfirm
          ref="dialogDeleteConfirm"
          @confirm="deleteLayer"
          title="Delete layer"
          description="Are you sure you want to delete this layer?"
        >
          <Button size="sm" variant="ghost" class="w-6 h-6 p-1">
            <Trash2 class="text-red-400" />
          </Button>
        </DialogConfirm> -->
      </div>
    </div>

    <div class="ml-2">
      <div>
        <!-- Parent source and layer to control z-index, don't care what inside -->
        <MapSource
          :map="map"
          type="geojson"
          :id="layerId + '-source'"
          :data="{
            type: 'FeatureCollection',
            features: []
          }"
        >
          <MapLayer
            :map="map"
            :id="layerId"
            :source="layerId + '-source'"
            type="fill"
            :before-id="beforeId"
            @mounted="() => handleMounted()"
          >
            <div v-if="isSettings" class="pb-2 pr-2">
              <h4>Opacity</h4>
              <div class="flex items-center justify-between">
                <Slider
                  :default-value="[opacity]"
                  :max="100"
                  :step="1"
                  @update:model-value="onOpacityChange($event)"
                />
                <span class="w-7 ml-1 flex">
                  {{opacity}}
                </span>
              </div>
            </div>
            <div>
              <LayerPlanetscope
                v-if="item.value.properties.type === 0"
                :before-id="beforeId"
                :group-id="layerId"
                :options="{
                  layout: {
                    visibility: isVisible ? 'visible' : 'none',
                  },
                }"
              />

              <LayerPlanetVars
                v-if="item.value.properties.type === 1"
                :layer="item.value.properties.meta"
                :before-id="beforeId"
                :group-id="layerId"
                :options="{
                  layout: {
                    visibility: isVisible ? 'visible' : 'none',
                  },
                }"
              />

              <LayerMosaic
                v-if="item.value.properties.type === 2"
                :before-id="beforeId"
                :group-id="layerId"
                :options="{
                  minzoom: 0,
                  maxzoom: 22,
                  layout: {
                    visibility: isVisible ? 'visible' : 'none',
                  },
                }"
              />
            </div>
          </MapLayer>
        </MapSource>
      </div>
    </div>


    <div
      v-if="instruction"
      class="absolute h-full w-full top-0 border-blue-500 "
      :style="{
        left: `${instruction?.currentLevel * instruction?.indentPerLevel}px`,
        width: `calc(100% - ${instruction?.currentLevel * instruction?.indentPerLevel}px)`,
      }"
      :class="{
        '!border-b-2': instruction?.type === 'reorder-below',
        '!border-t-2': instruction?.type === 'reorder-above',
        '!border-2 rounded': instruction?.type === 'make-child',
    }"
    />
  </TreeItem>
</template>
