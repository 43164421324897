<script setup>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

import { LayerPlanetscopeItem } from '@/components/layers'

const props = defineProps({
  beforeId: {
    type: String,
    required: true
  },
  groupId: {
    type: String,
    required: true
  },
  options: {
    type: Object,
    required: false,
    default: () => ({})
  }
})

const store = useStore()
const mosaics = computed(() => store.getters['geo/getLayers'])

onMounted(async () => {
  await store.dispatch('geo/fetchLayersToken')
  await store.dispatch('geo/fetchLayers')
})
</script>

<template>
  <LayerPlanetscopeItem
    v-if="mosaics?.globals?.length"
    :mosaics="mosaics?.globals"
    :before-id="beforeId"
    :group-id="groupId"
    :options="options"
  />
</template>
