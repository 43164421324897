<script setup>
import {ref, computed, onMounted, onUnmounted} from 'vue'
import {useStore} from 'vuex'
import {debounce, last} from 'lodash'
import {formatTime} from '@/utils/datetime'

import { Slider } from '@/components/ui/slider'
import { MapSource, MapLayer } from '@/components/map'

const protocol = location.protocol === 'https:' ? 'https' : 'http'
const host = `${protocol}://${window.location.host}`

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const map = computed(() => store.getters['geo/getMap'])
const layersToken = computed(() => store.getters['geo/getTilesToken'])

const props = defineProps({
  mosaics: {
    type: Array,
    required: true
  },
  beforeId: {
    type: String,
    required: true
  },
  groupId: {
    type: String,
    required: true
  },
  options: {
    type: Object,
    required: false,
    default: () => ({})
  }
})

const mosaicsLength = computed(() => props.mosaics.length)
const lastDate = computed(() => formatTime(props.mosaics[mosaicsLength.value - 1].last_acquired, settings.value.tz, 'D MMMM YYYY'))

const sliderSelected = ref(0)
const selectedMosaic = computed(() => props.mosaics[sliderSelected.value])
const selectedMosaicDate = computed(() => formatTime(selectedMosaic.value.last_acquired, settings.value.tz, 'D MMMM YYYY'))

const lastLayedId = ref(null)

const handleChange = (value) => {
  if (value) {
  }
}

const handleMosaicChange = (value) => {
  lastLayedId.value = `planet-${props.mosaics[value].name}-layer`
}


onMounted(() => {
  lastLayedId.value = `planet-${props.mosaics[0].name}-layer`
})
</script>

<template>
  <div>
    <div class="py-2 pr-2 pointer-events-auto">
      <div class="">
        <Slider
          class="min-w-fit"
          :default-value="[sliderSelected]"
          :max="mosaicsLength - 1"
          :step="1"
          @update:model-value="handleMosaicChange"
        />
        <div class="mt-3 flex justify-between items-center">
          <div class="text-gray-500 min-w-[120px] mr-3">{{ selectedMosaicDate }}</div>
          <div class="text-gray-500 min-w-fit ml-3">{{ lastDate }}</div>
        </div>
      </div>
    </div>

    <div v-if="map" v-for="mosaic in mosaics">
      <MapSource
        v-if="lastLayedId === `planet-${mosaic.name}-layer`"
        :map="map"
        type="raster"
        :id="`planet-${mosaic.name}-source`"
        :options="{
          tiles: [`${host}/tulum/tiles/1/${mosaic.name}/{z}/{x}/{y}.png?token=${layersToken}`],
          tileSize: 256,
        }"
      >
        <MapLayer
          :map="map"
          :id="`planet-${mosaic.name}-layer`"
          type="raster"
          :source="`planet-${mosaic.name}-source`"
          :before-id="beforeId"
          :group-id="groupId"
          :options="options"
        ></MapLayer>
      </MapSource>
    </div>
  </div>
</template>
