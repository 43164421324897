<script setup>
import { onMounted, onUnmounted } from 'vue'

const emit = defineEmits(['click', 'mounted'])
const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: true,
    validator: (value) =>
      ['fill', 'line', 'symbol', 'circle', 'heatmap', 'fill-extrusion', 'raster', 'hillshade', 'background', 'sky', 'slot'].includes(value),
  },
  options: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  beforeId: {
    type: String,
    required: false,
    default: null,
  },
  slot: {
    type: String,
    required: false,
    default: null,
  },
  groupId: {
    type: String,
    required: false,
    default: null,
  },
})

const addLayerListeners = (layer) => {
  props.map.on('mousemove', layer, (e) => {
    props.map.getCanvas().style.cursor = 'pointer'
  })

  props.map.on('mouseleave', layer, () => {
    props.map.getCanvas().style.cursor = 'default'
  })

  props.map.on('click', layer, (e) => {
    emit('click', e)
  })
}

const removeLayerListeners = (layer) => {
  props.map.off('mousemove', layer)
  props.map.off('mouseleave', layer)
  props.map.off('click', layer)
}

const addLayer = async () => {
  if (!props.map.getLayer(props.id)) {
    const layerOptions = {
      id: props.id,
      type: props.type,
      ...props.options,
      minzoom: 0,
      maxzoom: 24,
    }

    if (props.slot) {
      layerOptions.slot = props.slot
    }

    if (props.source) {
      layerOptions.source = props.source
    }

    if (props.groupId) {
      layerOptions.metadata = {
        groupId: props.groupId,
      }
    }

    if (props.beforeId && props.map.getLayer(props.beforeId)) {
      props.map.addLayer(layerOptions, props.beforeId)
    } else {
      props.map.addLayer(layerOptions)
    }

    if (props.type !== 'slot') {
      addLayerListeners(props.id)
    }
  } else {
    console.warn(`Layer '${props.id}' already exists on the map.`)
  }

  setTimeout(() => {
    emit('mounted')
  }, 1000)
}

const removeLayer = () => {
  if (props.map.getLayer(props.id)) {
    if (props.image) {
      props.map.removeImage(props.id)
    }
    props.map.removeLayer(props.id)
    removeLayerListeners(props.id)
  }
}

onMounted(async () => {
  await addLayer()
})

onUnmounted(() => {
  removeLayer()
})
</script>

<template>
  <slot></slot>
</template>
