export const state = {
  typeToLayer: {
    0: 'PlanetScope',
    1: 'PlanetVars',
    2: 'PlanetSkySat'
  },
  layers: [
    {
      "uuid": "e91134db-6b15-4f07-bb99-b651c2e52afb",
      "type": "Feature",
      "geometry": JSON.parse("{\"type\":\"Polygon\",\"coordinates\":[[[15.8101482,-2.38344797],[15.98107258,-2.38344797],[15.98107258,-2.2413222],[15.8101482,-2.2413222],[15.8101482,-2.38344797]]]}"),
      "properties": {
        "organization_id": 1,
        "type": 0,
        "visible": false,
        "code": "e91134db-6b15-4f07-bb99-b651c2e52afb",
        "name": "PlanetScope",
        "color": "#FF0000"
      }
    },
    {
      "uuid": "e21134db-6b15-4f07-bb99-b651c2e52afb",
      "type": "Feature",
      "geometry": {
          "type": "Polygon",
          "coordinates": [
              [
                  [67.91368157793961, 23.817345404236214],
                  [68.04667781890564, 23.817345404236214],
                  [68.04667781890564, 23.900308730219415],
                  [67.91368157793961, 23.900308730219415],
                  [67.91368157793961, 23.817345404236214]
              ]
          ]
      },
      "properties": {
        "organization_id": 1,
        "type": 2,
        "visible": false,
        "code": "e21134db-6b15-4f07-bb99-b651c2e52afb",
        "name": "Planet SkySat Demo",
        "color": "#FF0000"
      }
    }
    // {
    //   "uuid": "e11134db-6b15-4f07-bb99-b651c2e52afb",
    //   "type": "Feature",
    //   "geometry": JSON.parse("{\"type\":\"Polygon\",\"coordinates\":[[[15.8101482,-2.38344797],[15.98107258,-2.38344797],[15.98107258,-2.2413222],[15.8101482,-2.2413222],[15.8101482,-2.38344797]]]}"),
    //   "properties": {
    //     "type": 1,
    //     "visible": true,
    //     "code": "e11134db-6b15-4f07-bb99-b651c2e52afb",
    //     "name": "Forest Carbon 3m",
    //     "color": "#00FF00"
    //   }
    // },
    // {
    //   "uuid": "e21134db-6b15-4f07-bb99-b651c2e52afb",
    //   "type": "Feature",
    //   "geometry": JSON.parse("{\"type\":\"Polygon\",\"coordinates\":[[[15.8101482,-2.38344797],[15.98107258,-2.38344797],[15.98107258,-2.2413222],[15.8101482,-2.2413222],[15.8101482,-2.38344797]]]}"),
    //   "properties": {
    //     "type": 2,
    //     "visible": true,
    //     "code": "e21134db-6b15-4f07-bb99-b651c2e52afb",
    //     "name": "Forest Carbon 30m",
    //     "color": "#0000FF"
    //   }
    // }
  ]
}
