<script setup>
import {computed, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {formatTime} from "@/utils/datetime"
import {API, HOST_TULUM} from '@/utils/http'

import {bbox} from '@turf/turf'
import {CheckCircle2, XCircle} from 'lucide-vue-next'

import { LayerChart } from '@/components/layers'
import {Spinner} from '@/components/ui/spinner'
import { MapSource, MapLayer } from '@/components/map'


const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const map = computed(() => store.getters['geo/getMap'])

const results = ref([])
const activeResult = ref(null)


const props = defineProps({
  beforeId: {
    type: String,
    required: true
  },
  groupId: {
    type: String,
    required: true
  },
  options: {
    type: Object,
    required: true
  },
  layer: {
    type: Object,
    required: true
  }
})

const feature = computed(() => {
  return {
    type: 'Feature',
    geometry: JSON.parse(props.layer.geometry),
  }
})

const statusToLabel = {
  '-9': 'Preparing Data (Failed)',
  '-8': 'Cleaning Up (Failed)',
  '-7': 'Uploading Tiles (Failed)',
  '-6': 'Generating Tiles (Failed)',
  '-5': 'Merging Quads (Failed)',
  '-4': 'Uploading Data (Failed)',
  '-3': 'Delivering Data (Failed)',
  '-2': 'Ordering Data (Failed)',
  '-1': 'Starting (Failed)',
  '0': 'Done',
  '1': 'Starting',
  '2': 'Ordering Data',
  '3': 'Delivering Data',
  '4': 'Uploading Data',
  '5': 'Merging Quads',
  '6': 'Generating Tiles',
  '7': 'Uploading Tiles',
  '8': 'Cleaning Up',
  '9': 'Preparing Data'
}


const typeToLabel = {
  'forest_carbon_diligence_30m': 'Forest Carbon Diligence 30m',
  'forest_carbon_diligence_3m': 'Forest Carbon Diligence 3m'
}

const idToSource = {
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30': 'Aboveground Carbon Density 30m (v1.0.0)',
  'CANOPY_HEIGHT_v1.0.0_30': 'Canopy Height 30m (v1.0.0)',
  'CANOPY_COVER_v1.0.0_30': 'Canopy Cover  30m (v1.0.0)',
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_3': 'Aboveground Carbon Density 3m (v1.0.0)',
  'CANOPY_HEIGHT_v1.0.0_3': 'Canopy Height 3m (v1.0.0)',
  'CANOPY_COVER_v1.0.0_3': 'Canopy Cover  3m (v1.0.0)'
}

const box = bbox(feature.value)
const topLeft = [box[0], box[3]]
const topRight = [box[2], box[3]]
const bottomRight = [box[2], box[1]]
const bottomLeft = [box[0], box[1]]
const corners = [
  topLeft,
  topRight,
  bottomRight,
  bottomLeft
]

const fetchResults = async () => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST_TULUM}/tasks/layers/${props.layer.uuid}/subscription/result`
    })
    return response.data.reverse()
  } catch (e) {
    console.error(e)
  }
}

onMounted(async () => {
  if (props.layer.status !== 0) return
  results.value = await fetchResults()
  if (!results.value?.length) return
  results.value = results.value.map((i, index) => {
    return {
      ...i,
      s3_url: `https://planet-basemaps.s3.us-west-2.amazonaws.com/layer-${i.id}.png`
    }
  })
  activeResult.value = results.value[0]
})
</script>

<template>
  <div class="border border-gray-200 rounded-sm mb-2 mr-2 py-1 px-2">
    <div>
      <p class="text-gray-500">{{idToSource[layer.source_id]}}</p>
      <div class="flex items-center my-1">
        <div class="mr-2">
          <CheckCircle2 v-if="layer.status === 0" class="text-green-600 w-5 h-5" />
          <XCircle v-else-if="parseInt(layer.status) < 0" class="text-red-400 w-5 h-5" />
          <Spinner v-else class="w-5 h-5" />
        </div>
        <h4>{{ statusToLabel[layer.status] }}</h4>
      </div>

      <p class="text-gray-500 mb-0">{{formatTime(layer.created_at, settings.tz, 'D MMMM YYYY HH:mm')}}</p>
    </div>

    <div>
      <LayerChart
        v-if="layer.status === 0 && results.length"
        :layer="layer"
        :feature="feature"
        :key="feature"
        :results="results"
        @update:activeResult="(value) => { if (activeResult !== value) activeResult = value }"
      />
    </div>
  </div>

  <MapSource
    v-if="map"
    v-for="result in results"
    :map="map"
    type="image"
    :id="`result-source-${result.id}`"
    :url="result.s3_url"
    :coordinates="corners"
  >
    <MapLayer
      v-if="result.id === activeResult.id"
      :map="map"
      :id="`result-layer-${result.id}`"
      :source="`result-source-${result.id}`"
      type="raster"
      :options="{
        ...props.options,
        paint: {
          'raster-fade-duration': 0,
          'raster-resampling': 'nearest'
        }
      }"
      :before-id="beforeId"
      :group-id="groupId"
      ></MapLayer>
  </MapSource>
</template>
